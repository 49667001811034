<template>
  <div>
    <AppStatusCart />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "RoomRouterView",
  components: {
    AppStatusCart: () => import("~cp/AppStatusCart/AppStatusCart"),
  },
  beforeRouteEnter(to, from, next) {
    if (!to.name) {
      next({ name: "Home" });
    }
    next((vm) => {
      if (to.query.quota_id) {
        vm.$store.dispatch("quota/getQuotaById", to.query.quota_id).then(() => {
          if (
            (!vm.$store.getters["authModule/isSuperUser"] &&
              vm.$store.getters["quota/isQuotaFreese"]) ||
            (vm.$store.getters["authModule/isSuperUser"] &&
              vm.$store.getters["quota/isQuotaSigned"])
          ) {
            vm.$store.commit("SET_LOADER_STATUS", true);
            vm.$router.push({ name: "Home" });
          }
        });
      }
    });
  },
};
</script>

<style></style>
